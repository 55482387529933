import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { get, isArray, isNil, isString, set, update } from 'lodash-es';
import { format, parseISO } from 'date-fns';
import {
  IJournalColumn,
  JOURNAL_COLUMN_TYPE_COUNT,
  JOURNAL_COLUMN_TYPE_DATE,
  JOURNAL_COLUMN_TYPE_DATE_TIME,
} from '../types/journal-column.type';
import {
  IJournalFilter,
  IJournalFilterParam,
  JOURNAL_FILTER_TYPE_DATE,
  JOURNAL_FILTER_TYPE_DATETIME,
  JOURNAL_FILTER_TYPE_MULTISELECT,
  JOURNAL_FILTER_TYPE_SELECT,
  JournalFilterConditionType,
} from '../types/journal-filter.type';
import { IJournalQuery, IJournalQueryFilter, IJournalResult } from '../types/journal-query.type';
import { JournalRowType } from 'src/app/lib/journal/types/journal.type';

@Injectable()
export class JournalService {
  constructor(private http: HttpClient) {}

  /**
   * Данные журнала
   */
  loadData(service: string, params: IJournalQuery): Observable<IJournalResult<JournalRowType>> {
    return this.http.post<IJournalResult<JournalRowType>>(service, params);
  }

  /**
   * Формирование списка фильтров в колонках запроса
   */
  prepareQueryInlineFilter(filtersParam: IJournalFilterParam[]): IJournalQueryFilter[] {
    return (
      filtersParam
        // Для быстрого поиска и при пустом значении нет смысла отправлять параметр
        .filter((filterParam) => isNil(filterParam.value) || filterParam.value === '')
        .map((filterParam) => {
          return {
            field: filterParam.field,
            // FIXME: по идее пустого условия быть не может
            operator: filterParam.condition || JournalFilterConditionType.like,
            values: this.formatFilterValue(filterParam),
          };
        })
    );
  }

  /**
   * Формирование списка фильтров запроса
   */
  prepareQueryFilter(filtersParam: IJournalFilterParam[]): IJournalQueryFilter[] {
    return filtersParam
      .filter((filterParam) => filterParam.enable)
      .map((filterParam) => {
        return {
          field: filterParam.field,
          // FIXME: по идее пустого условия быть не может
          operator: filterParam.condition || JournalFilterConditionType.equal,
          values: this.formatFilterValue(filterParam),
        };
      });
  }

  /**
   * Формирование значения фильтра для запроса
   * @param filterParam
   */
  formatFilterValue(filterParam: IJournalFilterParam): string[] | number[] | null {
    let values;

    if (
      filterParam.condition === JournalFilterConditionType.between &&
      !isNil(filterParam.valueStart) &&
      !isNil(filterParam.valueFinish)
    ) {
      values = [filterParam.valueStart, filterParam.valueFinish];
    } else if (isArray(filterParam.value)) {
      values = filterParam.value;
    } else if (isNil(filterParam.value)) {
      values = [];
    } else {
      values = [filterParam.value];
    }

    return values;
  }

  /**
   * Форматирование данных согласно настройкам
   */
  formatData(columns: IJournalColumn[], rows: JournalRowType[], pageIndex = 1, pageSize = 10) {
    let index = 1;
    for (const row of rows) {
      for (const col of columns) {
        const field = col.field;
        let val = get(row, field, '');

        // преобразовать относительно типа
        if (col.type) {
          switch (col.type) {
            case JOURNAL_COLUMN_TYPE_COUNT:
              if (pageIndex) {
                val = index + (pageIndex - 1) * pageSize;
              } else {
                val = index;
              }
              break;
            case JOURNAL_COLUMN_TYPE_DATE:
              val = val ? format(parseISO(val), 'dd.MM.yyyy') : '';
              break;
            case JOURNAL_COLUMN_TYPE_DATE_TIME:
              val = val ? format(parseISO(val), 'HH:mm:ss dd.MM.yyyy') : '';
              break;
            default:
            // nothing
          }
        }

        // вызвать свою функцию обработки
        if (col.renderFn) {
          val = col.renderFn(row, col);
        }

        set(row, field, val);
      }

      index++;
    }

    return rows;
  }

  /**
   * Подготовка фильтра
   */
  getParamFilter(filter: IJournalFilter, enable: boolean): IJournalFilterParam {
    let conditionValue: JournalFilterConditionType | undefined;

    if (filter.inline) {
      // для фильтра в колонок считается по-умолчанию нестрогий поиск
      conditionValue = filter.defaultCondition || JournalFilterConditionType.like;
    } else {
      conditionValue = filter.defaultCondition;
    }

    const filterValue = filter.defaultValue;

    // обрабатываем относительно типа
    let value;
    switch (filter.type) {
      case JOURNAL_FILTER_TYPE_MULTISELECT:
        value = isArray(filterValue) ? filterValue : [filterValue];
        value = value.filter((v) => !isNil(v));
        return {
          field: filter.field,
          condition:
            conditionValue === JournalFilterConditionType.like ? JournalFilterConditionType.equal : conditionValue,
          enable: enable,
          value: value,
        };
      case JOURNAL_FILTER_TYPE_SELECT:
        return {
          field: filter.field,
          condition:
            conditionValue === JournalFilterConditionType.like ? JournalFilterConditionType.equal : conditionValue,
          value: [!isNil(filterValue) ? filterValue : null],
          enable: enable,
        };
      case JOURNAL_FILTER_TYPE_DATETIME:
      case JOURNAL_FILTER_TYPE_DATE:
        if (conditionValue === JournalFilterConditionType.between) {
          return {
            field: filter.field,
            condition: conditionValue,
            enable: enable,
            value: null,
            valueStart:
              isArray(filterValue) && filterValue[0] ? format(parseISO(filterValue[0]), 'yyyy-MM-ddT00:00:00') : null,
            valueFinish:
              isArray(filterValue) && filterValue[0] ? format(parseISO(filterValue[0]), 'yyyy-MM-ddT00:00:00') : null,
          };
        } else {
          return {
            field: filter.field,
            enable: enable,
            condition:
              conditionValue === JournalFilterConditionType.like ? JournalFilterConditionType.equal : conditionValue,
            value: isString(filterValue) ? format(parseISO(filterValue), 'yyyy-MM-ddT00:00:00') : null,
          };
        }
      default:
        return {
          field: filter.field,
          condition: conditionValue,
          enable: enable,
          value: !isNil(filterValue) ? filterValue : null,
        };
    }
  }
}
