import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { startWith, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { cloneDeep, findIndex, forEach, get, isEmpty, isEqual, isNil, values } from 'lodash-es';

import { dateBetweenValidator, dateValidator, rangeNumberValidator } from '../utils/validators';
import {
  JOURNAL_FILTER_TYPE_DATE,
  IJournalFilter,
  JournalFilterConditionType,
  JOURNAL_FILTER_TYPE_NUMBER,
  JOURNAL_FILTER_TYPE_DATETIME,
  IJournalFilterParam,
  JOURNAL_FILTER_TYPE_CHECKBOX,
} from '../types/journal-filter.type';
import { controlName } from '../utils/helpers';
import { findInList } from '../utils/lodash';
import { formValidate } from '../utils/form';

@Component({
  selector: 'journal-filter',
  templateUrl: './journal-filter.component.html',
  styleUrls: ['./journal-filter.component.less'],
})
export class JournalFilterComponent implements OnInit, OnDestroy {
  /**
   * Доступные фильтры
   */
  @Input() availableFilter: IJournalFilter[] = [];
  /**
   * Значения фильтров
   */
  private _filtersParam: IJournalFilterParam[] = [];
  get filtersParam(): IJournalFilterParam[] {
    return this._filtersParam;
  }

  @Input() set filtersParam(value: IJournalFilterParam[]) {
    if (isEqual(value, this._filtersParam)) {
      return;
    }
    this._filtersParam = cloneDeep(value);
    this.createFilterForm(this._filtersParam);
  }

  /**
   * Загрузка данных
   */
  @Input() isLoadingData$?: Observable<boolean>;
  /**
   * Выбираемый фильтр
   */
  selectedFilter = new FormControl();
  /**
   * Настройки выбранных фильтров
   */
  filters: IJournalFilter[] = [];
  /**
   * Форма
   */
  form: FormGroup;

  protected destroy$: Subject<void> = new Subject<void>();

  constructor() {
    this.form = new FormGroup({});
  }

  ngOnInit() {
    this.isLoadingData$?.pipe(takeUntil(this.destroy$)).subscribe((disabled) => {
      if (disabled) {
        this.form.disable({
          emitEvent: false,
        });
      } else {
        forEach(this.form.controls, (control: AbstractControl) => {
          this.changeStateFilterControl(control as FormGroup, control.get('enable')?.value);
        });
      }
    });

    this.selectedFilter.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.handleSelectedFilterChange(value));
  }

  /**
   * Добавление нового фильтра
   */
  handleSelectedFilterChange(filter: IJournalFilter) {
    this.form.addControl(
      controlName(filter.field),
      this.createControl(filter, { enable: true } as IJournalFilterParam),
    );
    this.selectedFilter.setValue(null, { emitEvent: false });
    this.filters = [...this.filters, filter];
  }

  /**
   * Переданный контрол
   * @param field
   */
  getControl(field: string): FormGroup {
    return this.form.get(controlName(field)) as FormGroup;
  }

  /**
   * Формируем форму для фильтров
   */
  private createFilterForm(filterParams: IJournalFilterParam[]) {
    this.form = new FormGroup({});
    this.filters = [];

    for (const filterParam of filterParams) {
      const filter = findInList(this.availableFilter, 'field', filterParam.field);
      // если настройку журнала изменили и фильтр удалили
      if (!filter) {
        continue;
      }

      this.filters.push(filter);
      this.form.addControl(controlName(filter.field), this.createControl(filter, filterParam));
    }
  }

  /**
   * Формируем контрол фильтра
   * @param filter - настройка фильтра
   * @param filterParam - значение полей фильтра
   */
  private createControl(filter: IJournalFilter, filterParam: IJournalFilterParam) {
    const fieldControl = new FormControl(filter.field);
    const enableControl = new FormControl(isNil(filterParam.enable) ? filter.enable : filterParam.enable);
    const condition = isNil(filterParam.condition) ? filter.defaultCondition : filterParam.condition;
    const conditionControl = new FormControl(condition, [Validators.required]);

    let filterControl: FormGroup;
    switch (filter.type) {
      case JOURNAL_FILTER_TYPE_NUMBER:
        const valueStart = isNil(filterParam?.valueStart) ? get(filter?.defaultValue, 0, null) : filterParam?.valueStart;
        const valueFinish = isNil(filterParam?.valueFinish) ? get(filter?.defaultValue, 1, null) : filterParam?.valueFinish;

        filterControl = new FormGroup({
          field: fieldControl,
          enable: enableControl,
          condition: conditionControl,
          value: new FormControl(filterParam?.value || filter?.defaultValue, [Validators.required]),
          valueStart: new FormControl(
            condition === JournalFilterConditionType.between
              ? valueStart
              : null,
            [Validators.required, rangeNumberValidator(conditionControl)],
          ),
          valueFinish: new FormControl(
            condition === JournalFilterConditionType.between
              ? valueFinish
              : null,
            [Validators.required, rangeNumberValidator(conditionControl)],
          ),
        });
        break;
      case JOURNAL_FILTER_TYPE_DATE:
      case JOURNAL_FILTER_TYPE_DATETIME:
        filterControl = new FormGroup({
          field: fieldControl,
          enable: enableControl,
          condition: conditionControl,
          value: new FormControl(filterParam?.value || filter?.defaultValue, [dateValidator(conditionControl)]),
          valueStart: new FormControl(
            condition === JournalFilterConditionType.between
              ? filterParam?.valueStart || filter?.defaultValue[0]
              : null,
            [Validators.required, dateBetweenValidator(conditionControl)],
          ),
          valueFinish: new FormControl(
            condition === JournalFilterConditionType.between
              ? filterParam?.valueFinish || filter?.defaultValue[1]
              : null,
            [Validators.required, dateBetweenValidator(conditionControl)],
          ),
        });
        break;
      case JOURNAL_FILTER_TYPE_CHECKBOX:
        filterControl = new FormGroup({
          field: fieldControl,
          enable: enableControl,
          condition: conditionControl,
          value: new FormControl(isNil(filterParam.value) ? filter.defaultValue : filterParam.value),
        });
        break;
      default:
        filterControl = new FormGroup({
          field: fieldControl,
          enable: enableControl,
          condition: conditionControl,
          value: new FormControl(isNil(filterParam.value) ? filter.defaultValue : filterParam.value, [
            Validators.required,
          ]),
        });
    }

    // блокировка, разблокировка полей
    enableControl.valueChanges.pipe(
      startWith(enableControl.value),
      takeUntil(this.destroy$)
    ).subscribe((state) => {
      this.changeStateFilterControl(filterControl, state || false);
    });

    conditionControl.valueChanges.pipe(
      startWith(conditionControl.value),
      takeUntil(this.destroy$)
    ).subscribe((condition) => {
      this.changeStateConditionControl(filterControl, condition);
    })

    return filterControl;
  }

  /**
   * Смена условий
   */
  private changeStateConditionControl(filterControl: FormGroup, condition: JournalFilterConditionType | null | undefined) {
    if (condition === JournalFilterConditionType.isNull) {
      filterControl.get('value')?.removeValidators(Validators.required);
    } else {
      filterControl.get('value')?.addValidators(Validators.required);
    }
  }

  /**
   * Смена активности фильтра
   */
  private changeStateFilterControl(filterControl: FormGroup, state: boolean) {
    forEach(filterControl.controls, (control: AbstractControl, key: string) => {
      if (key !== 'enable') {
        if (state) {
          control.enable();
        } else {
          control.disable();
        }
      } else {
        control.enable({
          emitEvent: false,
        });
      }
    });
  }

  /**
   * Не отправлять форму по Enter
   */
  enterFrom($event: Event) {
    $event.preventDefault();
    return false;
  }

  /**
   * Валидация формы
   */
  public validate(form: FormGroup | FormControl = this.form) {
    formValidate(form, { onlySelf: true });
    return form.valid;
  }

  /**
   * Фильтрация
   */
  doFilter() {
    if (isEmpty(this.form.controls)) {
      return [];
    }

    if (this.validate()) {
      this._filtersParam = values(this.form.getRawValue());
      return this._filtersParam;
    }

    return false;
  }

  /**
   * Фильтр выбран
   * @param f
   */
  isSelected(f: IJournalFilter): boolean {
    return !!findInList(this.filters, 'field', f.field);
  }

  /**
   * Удалить фильтр
   */
  onRemove(filter: IJournalFilter) {
    this.filters = this.filters.filter((item) => item.field !== filter.field);
    this.form.removeControl(controlName(filter.field));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
