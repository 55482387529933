import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

import { Logger } from '../services/logger/logger';
import { environment } from 'src/environments/environment';
import { LOGGER } from '../consts/log.enum';
import { mocks } from 'src/app/mocks';
import { get } from 'lodash-es';

export const INTERCEPTOR_SKIP_MOCK_HEADER = 'X-Skip-Mock-Interceptor';

@Injectable()
export class HttpRequestMockInterceptor implements HttpInterceptor {
  constructor(
    private logger: Logger,
    private http: HttpClient,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has(INTERCEPTOR_SKIP_MOCK_HEADER)) {
      const headers = request.headers.delete(INTERCEPTOR_SKIP_MOCK_HEADER);
      return next.handle(request.clone({ headers }));
    }

    return this.mockHandle(request, next);
  }

  private mockHandle(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    for (const element of mocks) {
      if (request.url !== element.url) {
        continue;
      }

      // есть параметры
      if (element.params) {
        let hasParams = true;

        for (const paramName in element.params) {
          if (element.params[paramName].indexOf(get(request.body, paramName)) === -1) {
            hasParams = false;
          }
        }

        if (!hasParams) {
          return throwError(() => new HttpResponse({ status: 400, body: {} })).pipe(delay(500));
        }
      }

      this.logger.log('%cIntercepted mock services call', LOGGER.request, request.url);
      this.logger.log('%cRequest body', LOGGER.request, request.body);

      return this.http
        .get(`assets/mocks/${element.json}.json`, {
          headers: new HttpHeaders().set(INTERCEPTOR_SKIP_MOCK_HEADER, ''),
        })
        .pipe(
          delay(500),
          tap((data) => {
            this.logger.log('%cLoaded from json', LOGGER.requestResult, data);
          }),
          switchMap((data) => of(new HttpResponse({ status: element.httpCode || 200, body: data }))),
        );
    }

    return next.handle(request);
  }
}
