import { Component, OnInit } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { AppService } from 'src/app/shared/services/app/app.service';
import { findIndex, isArray } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { IMenu } from 'src/app/shared/types/menu.type';
import { environment } from 'src/environments/environment';
import { ProfileService } from 'src/app/shared/services/app/profile.service';
import { MENU } from 'src/app/layouts/menu';
import { ROUTE_AUTH, ROUTE_HELP, ROUTE_SCREEN } from 'src/app/shared/consts/routes.const';
import { IScreenConfiguration } from '../../pages/screen-configuration/types/screen-configuration.type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Logger } from 'src/app/shared/services/logger/logger.service';
import { SessionService } from 'src/app/shared/services/app/session.service';

@UntilDestroy()
@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.less'],
})
export class SidebarLayoutComponent implements OnInit {
  /**
   * Свернутое меню
   */
  @LocalStorage('isCollapsed')
  isCollapsed!: boolean;
  /**
   * Меню
   */
  menuList$: BehaviorSubject<IMenu[]> = new BehaviorSubject<IMenu[]>([]);

  readonly environment = environment;

  constructor(
    public router: Router,
    public appService: AppService,
    public logger: Logger,
    public profileService: ProfileService,
    public session: SessionService,
  ) {}

  async ngOnInit() {
    await this.updateMenu(null);
    this.appService.chosenBoard$.pipe(untilDestroyed(this)).subscribe(async (board) => {
      if (board?.id) {
        await this.updateMenu(board.id);
      }
    });
  }

  private async updateMenu(boardId: number | null) {
    const roles = this.profileService.getRoles();
    let screens: IScreenConfiguration[] = [];

    if (boardId) {
      try {
        screens = await this.appService.getScreens(boardId);
      } catch (error) {
        this.logger.error('Failed load screen', error);
      }
    }

    const menuList = MENU.filter(
      (menu: IMenu) => !isArray(menu.roles) || menu.roles.some((role) => roles.indexOf(role) > -1),
    );
    screens
      .filter((screen: IScreenConfiguration) => findIndex(screen.roles, (role) => roles.indexOf(role) > -1) !== -1)
      .forEach((screen) =>
        menuList.push({
          title: screen.name,
          url: `${ROUTE_SCREEN}/${screen.id}`,
          icon: screen.icon,
          roles: screen.roles || [],
        }),
      );

    menuList.push({
      title: 'Справочная информация',
      url: `${ROUTE_HELP}`,
      icon: 'question-circle',
    });
    this.menuList$.next(menuList);
  }

  logout() {
    this.router.navigate([`${ROUTE_AUTH}/logout`]);
    return false;
  }
}
