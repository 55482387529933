<nz-layout class="layout">
  <nz-header class="header">
    <div class="header-site">
      <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
        <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
      </span>
      {{ environment.appName }}
      <ng-container *ngIf="(appService.chosenBoard$ | async)?.name as chosenBoardName">
        (борт {{ chosenBoardName }})
      </ng-container>
    </div>
    <span class="header-user">
      <span class="header-user__role">
        {{ profileService.profile?.roleName }}
      </span>
      <span class="header-user__name">
        {{ profileService.profile?.fio }}
      </span>
      <a class="header-user__logout" (click)="logout()"> <i nz-icon nzType="logout" nzTheme="outline"></i> Выйти </a>
    </span>
  </nz-header>
  <nz-layout>
    <nz-sider nzCollapsible nzWidth="256px" [nzCollapsedWidth]="76" [nzCollapsed]="isCollapsed" [nzTrigger]="null">
      <sidebar-menu [menuList$]="menuList$" [isCollapsed]="isCollapsed"></sidebar-menu>
    </nz-sider>
    <nz-content>
      <div class="breadcrumb" *ngIf="!(appService.routeData$ | async)?.hideBreadcrumb; else noBreadcrumb">
        <nz-breadcrumb [nzAutoGenerate]="true">
          <nz-breadcrumb-item>
            <a routerLink="/"><span nz-icon nzType="home" nzTheme="outline"></span></a>
          </nz-breadcrumb-item>
        </nz-breadcrumb>
      </div>
      <ng-template #noBreadcrumb>
        <div class="breadcrumb-empty">&nbsp;</div>
      </ng-template>
      <div class="d-flex flex-column" [ngClass]="{ 'inner-content': !(appService.routeData$ | async)?.hasBlocks }">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
