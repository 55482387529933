import { Injectable } from '@angular/core';
import { BehaviorSubject, bufferTime, exhaustMap, filter, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { RouteData } from 'src/app/shared/types/route.type';
import { $WebSocket } from 'src/app/lib/websocket/websocket.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IJournalResult } from 'src/app/lib/journal/types/journal-query.type';
import { IScreenConfiguration } from 'src/app/pages/screen-configuration/types/screen-configuration.type';
import {
  IWebSocketResponse,
  IWebSocketMachine,
  IWebSocketResponseEvent,
  IExperimentInfo,
  IExperimentInfoEvent,
  IWebSocketResponseError,
} from 'src/app/shared/types/response.type';
import { getSocketPath } from 'src/app/shared/helpers/websocket';
import { IBoard } from 'src/app/pages/board/types/board.type';
import { JournalFilterConditionType } from 'src/app/lib/journal/index';
import { isEqual, isNil, negate } from 'lodash-es';
import { ProfileService } from './profile.service';
import { Logger } from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  routeData$ = new BehaviorSubject<RouteData | null>(null);

  /**
   * WebSocket Endpoint
   */
  private wsEndpoint$!: $WebSocket<IWebSocketResponse>;
  /**
   * Приемник сообщений в WebSocket
   */
  public wsEmitter = new Subject<unknown>();

  /**
   * Информация о текущем состоянии системы
   */
  private machine$ = new BehaviorSubject<IWebSocketMachine | null>(null);
  /**
   * Выбранный борт
   */
  public chosenBoard$ = new BehaviorSubject<IBoard | null>(null);
  /**
   * Текущий эксперимент
   */
  private experiment$ = new BehaviorSubject<IExperimentInfoEvent | null>(null);

  constructor(
    private logger: Logger,
    private message: NzMessageService,
    private http: HttpClient,
    private profileService: ProfileService,
  ) {
    this.wsEndpoint$ = new $WebSocket(getSocketPath('/new'), undefined, {
      maxTimeout: 600000,
      reconnectIfNotNormalClose: true,
    });

    // Группирует и отправляет запросы в WebSocket
    this.wsEmitter
      .pipe(
        bufferTime(1000),
        map((data) =>
          data.reduce((acc: unknown[], obj: unknown) => {
            if (!acc.some((item) => isEqual(item, obj))) {
              acc.push(obj);
            }
            return acc;
          }, []),
        ),
        filter((requests) => requests.length > 0),
      )
      .subscribe((data) => {
        this.wsEndpoint$.send(data);
      });

    // Пинг-понг (поддержание Websocket соединения активным)
    const pingTimeout = 60000;
    const doPing = () => {
      this.wsEmitter.next({ type: 'ping', unix: new Date().getTime() });
    };
    let nextPing = setTimeout(doPing, pingTimeout);
    this.wsEndpoint$.getDataStreamNew().subscribe(() => {
      clearTimeout(nextPing);
      nextPing = setTimeout(doPing, pingTimeout);
    });

    // Аутентификация
    this.wsEndpoint$
      .getDataStreamNew()
      .pipe(
        filter(
          (data): data is IWebSocketResponseEvent => data.type === 'auth_required' || data.type === 'auth_invalid',
        ),
        // Не выполняем новый запрос, если предыдущий ещё не был завершён
        exhaustMap((data) => {
          if (data.type === 'auth_invalid' || this.profileService.isTokenExpired()) {
            return this.http.get('/auth/me').pipe(map(() => this.profileService.accessToken));
          }
          return of(this.profileService.accessToken);
        }),
        filter((token) => token !== ''),
      )
      .subscribe((token) => {
        this.wsEmitter.next({ type: 'auth', token });
      });

    // Отображение ошибок
    this.wsEndpoint$
      .getDataStreamNew()
      .pipe(filter((data): data is IWebSocketResponseError => data.type === 'error'))
      .subscribe((error) => {
        this.message.error(error.message);
      });

    // Events
    this.wsEndpoint$
      .getDataStreamNew()
      .pipe(
        filter((data): data is IWebSocketResponseEvent => data.type === 'event'),
        map((data) => data.event),
      )
      .subscribe((event) => {
        if (event.eventType === 'MachineInfo') {
          const res = event as IWebSocketMachine;
          this.machine$.next(res);
          if (this.chosenBoard$.getValue()?.id !== res?.board?.id) {
            this.chosenBoard$.next(res.board);
          }
        } else if (event.eventType === 'ExperimentInfo') {
          const res = event as IExperimentInfoEvent;
          this.experiment$.next(res);
        }
      });
  }

  getDataStream(): Observable<IWebSocketResponse> {
    return this.wsEndpoint$.getDataStreamNew();
  }

  getMachineInfo(): Observable<IWebSocketMachine> {
    if (this.machine$.value === null) {
      this.wsEmitter.next({ type: 'request', data: { method: 'command', command: 'GET_MACHINE_INFO' } });
    } else {
      setTimeout(() => {
        this.machine$.next(this.machine$.value);
      }, 10);
    }
    return this.machine$.pipe(filter(negate(isNil)));
  }

  getExperimentInfo(): Observable<IExperimentInfo | null> {
    if (this.experiment$.value === null) {
      this.wsEmitter.next({ type: 'request', data: { method: 'command', command: 'GET_EXPERIMENT_INFO' } });
    } else {
      setTimeout(() => {
        this.experiment$.next(this.experiment$.value);
      }, 10);
    }
    return this.experiment$.pipe(map((res) => res?.data ?? null));
  }

  /**
   * Список экранов для меню
   */
  getScreens(boardId: number): Promise<IScreenConfiguration[]> {
    return firstValueFrom(
      this.http
        .post<IJournalResult<IScreenConfiguration>>('/layouts/search', {
          page: 0,
          perPage: 999,
          columns: ['id', 'name', 'roles', 'icon'],
          filters: [
            {
              field: 'board.id',
              operator: JournalFilterConditionType.equal,
              values: [boardId],
            },
            {
              field: 'showInNavigation',
              operator: JournalFilterConditionType.equal,
              values: [true],
            },
          ],
          order: [],
        })
        .pipe(map((res) => res.items)),
    );
  }
}
