import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { filter, interval, Observable, switchMap, take, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';

import { Logger } from '../services/logger/logger';
import { LOGGER } from '../consts/log.enum';
import { MESSAGE_ERROR_TOKEN_EXPIRED } from '../consts/messages.const';
import { ProfileService } from '../services/app/profile.service';
import { AUTH_TOKEN_HEADER_KEY, AUTH_TOKEN_PREFIX, AUTH_TOKEN_SKIP } from 'src/app/shared/consts/http.const';
import { IAuthLogin } from 'src/app/shared/types/auth.type';
import { isNull, negate } from 'lodash-es';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(
    private logger: Logger,
    private storage: LocalStorageService,
    private profileService: ProfileService,
    private modal: NzModalService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let newRequest: HttpRequest<unknown> = request;

    // add token auth
    const accessToken = this.profileService.accessToken;
    if (!newRequest.headers.has(AUTH_TOKEN_SKIP) && accessToken) {
      if (this.profileService.isTokenExpired()) {
        return this.handle401Error(newRequest, next);
      }

      newRequest = this.addTokenHeader(newRequest, accessToken);
    }

    return next.handle(newRequest).pipe(
      catchError((res) => {
        // для 401
        if (res instanceof HttpErrorResponse && !newRequest.headers.has(AUTH_TOKEN_SKIP) && res.status === 401) {
          return this.handle401Error(newRequest, next);
        }

        return throwError(() => res);
      }),
    );
  }

  get isRefreshing(): boolean {
    return this.storage.retrieve('isRefreshingAccessToken');
  }

  set isRefreshing(value: boolean) {
    this.storage.store('isRefreshingAccessToken', value);
  }

  /**
   * Обработка истекшего токена
   * @param request
   * @param next
   * @private
   */
  private handle401Error(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.isRefreshing) {
      const refreshToken = this.profileService.refreshToken;
      if (refreshToken) {
        this.logger.log(`%cRefresh token`, LOGGER.request);
        this.isRefreshing = true;

        const refreshRequest = new HttpRequest('POST', '/auth/session/refresh', { refreshToken });

        return next.handle(refreshRequest).pipe(
          filter((event: HttpEvent<unknown>): event is HttpResponse<IAuthLogin> => event instanceof HttpResponse),
          map((response) => response.body as IAuthLogin),
          finalize(() => {
            this.isRefreshing = false;
          }),
          switchMap((token: IAuthLogin) => {
            this.profileService.setToken(token);
            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError((res) => {
            // для повторной ошибки 401
            if (res instanceof HttpErrorResponse && res.status === 401) {
              this.logger.error(`Refresh token failed`, res);

              this.profileService.logout();
              this.modal.error({
                nzTitle: MESSAGE_ERROR_TOKEN_EXPIRED,
                nzClosable: false,
                nzOnOk: () => {
                  window.location.reload();
                },
              });
            }

            return throwError(() => res);
          }),
        );
      }
    }

    // Каждые 100 мс проверяем токен доступа, т.к. он может обновиться как из параллельно запущенного запроса,
    // так и из соседней вкладки.
    // Если в течении установленного лимита не был получен свежий токен, то выполняем запрос со старым токеном
    // (скорее всего он выполнится неудачно, что приведёт к разлогиниванию)
    return interval(100).pipe(
      map((iter) => {
        // 15 seconds limit
        if (this.profileService.isTokenExpired() && iter < 150) {
          return null;
        } else {
          // Снимаем флаг Refreshing, вероятно предыдущий запрос завис
          this.isRefreshing = false;
          return this.profileService.accessToken;
        }
      }),
      filter(negate(isNull)),
      take(1),
      switchMap((token: string) => next.handle(this.addTokenHeader(request, token))),
    );
  }

  /**
   * Добавляем токен авторизации
   */
  private addTokenHeader(request: HttpRequest<unknown>, token: string) {
    return request.clone({
      headers: request.headers.set(AUTH_TOKEN_HEADER_KEY, AUTH_TOKEN_PREFIX + token),
    });
  }
}
