import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Logger } from '../services/logger/logger';
import { environment } from 'src/environments/environment';
import { LOGGER } from '../consts/log.enum';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private logger: Logger) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const newRequest = request.clone({
      url: environment.restApi + request.url,
    });

    this.logger.log('%cService call ', LOGGER.request, newRequest.url);

    return next.handle(newRequest);
  }
}
