import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import ru from '@angular/common/locales/ru';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { ru_RU } from 'ng-zorro-antd/i18n';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgProgressModule } from 'ngx-progressbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { HttpRequestMockInterceptor } from './shared/interceptors/http-request-mock.interceptor';
import { HttpRequestInterceptor } from './shared/interceptors/http-request.interceptor';
import { HttpAuthInterceptor } from './shared/interceptors/http-auth.interceptor';
import { HttpResponseInterceptor } from './shared/interceptors/http-response.interceptor';
import { LoggerModule } from './shared/services/logger';
import { LayoutModule } from './layouts/layout.module';

// Настройка локали
registerLocaleData(ru);
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { ru as dateRu } from 'date-fns/locale';
setDefaultOptions({ locale: dateRu });

// Настройка прогресса
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { NgProgressHttpModule } from 'ngx-progressbar/http';

// Настройка графика
import { IconsProviderModule } from 'src/app/icons-provider.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule, // NOTE: now use dynamic load
    LoggerModule.forRoot(environment.logLevel),
    NgxWebstorageModule.forRoot({
      prefix: environment.prefixSession,
      separator: '.',
      caseSensitive: true,
    }),
    NgProgressModule.withConfig({
      color: '#2dabc1',
      thick: true,
    }),
    NgProgressRouterModule,
    NgProgressHttpModule,
    LayoutModule,
    NzModalModule,
    NzMessageModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: ru_RU },
    {
      provide: LOCALE_ID,
      useValue: 'ru',
    },
    // Настройки библиотеки компонентов
    {
      provide: NZ_CONFIG,
      useValue: {
        useStaticLoading: {
          codeEditor: true,
        },
        message: {
          nzDuration: 10000,
        },
      },
    },
    // Настройка HTTP перехватчиков
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    environment.isMock ? { provide: HTTP_INTERCEPTORS, useClass: HttpRequestMockInterceptor, multi: true } : [],
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
